import { FlexCol } from "../FlexCol";
import { BaseCard } from "../../form/base.container";
import { FlexRow } from "../FlexRow";
import { formatNumber } from "../../../util/number.util";
import { Box, Skeleton } from "@chakra-ui/react";

export const MetricsCard = ({
  title,
  subtitle,
  data,
  unit = "",
  formatTotal = (total) => total,
  loading,
}: {
  title: string;
  subtitle?: string;
  data: { label: string; value: number }[];
  unit?: string;
  formatTotal?: (total: number) => string | number;
  loading?: boolean;
}) => {
  const totalValue = data.reduce((acc, item) => acc + item.value, 0);

  return (
    <BaseCard
      boxShadow="lg"
      maxW="215px"
      h={{ base: "100%", md: "140px" }}
      p={{ base: "6px", md: "12px" }}
      fontSize="14px"
    >
      <FlexCol>
        <FlexCol gap="0">
          <div className="text-[16px] text-black">{title}</div>
          <div className="text-[12px] font-normal">{subtitle}</div>
        </FlexCol>

        {loading ? (
          <Skeleton height="100%" />
        ) : (
          data.map((item, index) => (
            <FlexRow key={index} justifyContent="space-between">
              <div>{item.label}:</div>
              <div>
                {formatNumber((item.value ?? 0).toFixed(2))} {unit}
              </div>
            </FlexRow>
          ))
        )}

        <FlexRow justifyContent="space-between" alignItems="center">
          <Box fontWeight="bold" color="brand.green.200">
            Total
          </Box>
          <Box
            fontWeight="bold"
            color="brand.green.200"
            fontFamily="zolo-header"
          >
            {formatTotal(totalValue)} {unit}
          </Box>
        </FlexRow>
      </FlexCol>
    </BaseCard>
  );
};
