import { useEffect, useState } from "react";
import { PieChart, Pie, Label, Cell, Text } from "recharts";
import { CardContent } from "../../../third-party/shadcn/card";
import { FlexRow } from "../FlexRow";
import { formatNumber } from "../../../util/number.util";
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "../../../third-party/shadcn/chart";

export interface PieChartDataItem {
  label: string;
  value: number;
}

interface ChartData {
  label: string;
  value: number;
  fill: string;
}

const pastelColors = [
  "#d28343",
  "#265542",
  "#ffdf6d",
  "#f8a5c2",
  "#63cdda",
  "#ea8685",
  "#596275",
  "#574b90",
  "#f3a683",
];

function getRandomColor() {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export default function PieChartComponent({
  title,
  data,
  width = 200,
  height = 200,
  unit = "",
  titleClassname,
}: {
  title: string;
  data: PieChartDataItem[];
  width?: number;
  height?: number;
  titleClassname?: string;
  unit?: "kg" | "";
}) {
  const [total, setTotal] = useState(0);
  const [chartData, setChartData] = useState<ChartData[]>([]);
  const [isEmpty, setIsEmpty] = useState(false);

  useEffect(() => {
    const chartData = data.map((item, index) => ({
      label: item.label,
      value: Math.round(item.value * 100) / 100,
      fill:
        index < pastelColors.length ? pastelColors[index] : getRandomColor(),
    }));

    const isEmpty =
      !chartData?.length || chartData.every((item) => !item.value);

    setTotal(chartData.reduce((acc, item) => acc + item.value, 0));

    setIsEmpty(isEmpty);
    setChartData(chartData);
  }, [data]);

  // Custom label renderer
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
  }: any) => {
    const value = (percent * 100).toFixed(0);
    if (value === "0") return null;

    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <Text
        x={x}
        y={y}
        fill="white"
        textAnchor="middle"
        dominantBaseline="central"
        fontWeight="bold"
        fontSize={14}
      >
        {percent >= 0.05 ? `${(percent * 100).toFixed(0)}%` : null}
      </Text>
    );
  };

  return (
    <CardContent className="pb-5 md:pb-0">
      <ChartContainer config={{}}>
        <div className="flex flex-col md:flex-row items-center">
          {isEmpty ? (
            <div className="bg-gray-100 rounded-[50%] w-[220px] h-[220px] mx-[28px]" />
          ) : (
            <PieChart width={width} height={height}>
              <Pie
                data={chartData}
                dataKey="value"
                nameKey="label"
                cx="50%"
                cy="50%"
                label={renderCustomizedLabel}
                labelLine={false}
              >
                {chartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.fill} />
                ))}
              </Pie>
              <ChartTooltip
                content={
                  <ChartTooltipContent
                    formatter={(value, name, item, index, payload) => (
                      <>
                        <span className="text-sm font-medium">{name}</span>
                        <span className="font-mono font-medium tabular-nums text-foreground pl-[6px]">
                          {((+value / total) * 100).toFixed(0)}%
                        </span>
                      </>
                    )}
                  />
                }
                wrapperStyle={{
                  backgroundColor: "white",
                  border: "none",
                  borderRadius: "5px",
                }}
                cursor={false}
              />
            </PieChart>
          )}

          <div className="flex flex-col gap-3">
            <h2 className={`text-lg font-medium mb-2 ${titleClassname}`}>
              {title}
            </h2>

            {chartData.map((item, index) => (
              <div
                key={index}
                className="flex flex-row justify-between items-center gap-2"
              >
                <FlexRow>
                  <div
                    className="h-3 w-6"
                    style={{ backgroundColor: item.fill }}
                  ></div>

                  <div className="text-sm font-semibold max-w-[145px]">
                    {item.label}
                  </div>
                </FlexRow>

                <div className="text-sm font-medium">
                  {formatNumber(item.value)} {unit}
                </div>
              </div>
            ))}
          </div>
        </div>
      </ChartContainer>
    </CardContent>
  );
}
