import { MetricsCard } from "./MetricsCard";
import { formatNumber } from "../../../util/number.util";

export const CircularEconomyMetrics = ({
    reuseInventory,
    recyclingInventory,
    subtitle,
    loading,
  }: {
    reuseInventory: number;
    recyclingInventory: number;
    subtitle: string;
    loading?: boolean;
  }) => {
    const data = [
      { label: "Re-use", value: reuseInventory },
      { label: "Recycle", value: recyclingInventory },
    ];
  
    return (
      <MetricsCard
        title="🌀 Circular Economy"
        data={data}
        subtitle={subtitle}
        formatTotal={(total) => formatNumber(total.toFixed(2))}
        loading={loading}
      />
    );
  };