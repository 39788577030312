import { MetricsCard } from "./MetricsCard";
import { formatNumber } from "../../../util/number.util";

export const SustainabilityMetrics = ({
  reuseWeight,
  recycleWeight,
  subtitle,
  loading,
}: {
  reuseWeight: number;
  recycleWeight: number;
  subtitle: string;
  loading?: boolean;
}) => {
  const data = [
    {
      label: "Re-use",
      value: reuseWeight,
    },
    {
      label: "Recycle",
      value: recycleWeight,
    },
  ];

  return (
    <MetricsCard
      loading={loading}
      title="♻️ Sustainability Impact"
      data={data}
      unit="kg"
      formatTotal={(total) => formatNumber(total.toFixed(2))}
      subtitle={subtitle}
    />
  );
};
