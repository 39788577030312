import { FlexRow } from "../../../shared/FlexRow";
import { useEffect, useState } from "react";
import { branchService } from "../../../../service/company/branch.service";
import { BranchInventoryMetricModel } from "../../../../models/inventory-metric.model";
import { CircularEconomyMetrics } from "../../../shared/metrics/CircularEconomyCard";
import { SocialImpactMetrics } from "../../../shared/metrics/SocialImpactCard";
import { SustainabilityMetrics } from "../../../shared/metrics/SustainabilityCard";

export const BranchDetailMetrics = ({ branchId }: { branchId?: string }) => {
  const [loading, setLoading] = useState(true);
  const [metrics, setMetrics] = useState<{
    totalReuse: number;
    totalRecycle: number;
    reuseWeight: number;
    recycleWeight: number;
    totalImpact: number;
  }>(null);

  const init = async () => {
    setLoading(true);
    await branchService.getAllBranchMetrics().then((metrics) => {
      calculateMetrics(metrics);
    }).finally(() => {
      setLoading(false);
    });
  };

  const calculateMetrics = (metrics: BranchInventoryMetricModel[]) => {
    let branchMetrics = metrics;
    if (branchId) {
      branchMetrics = metrics.filter((x) => x.branchId === branchId);
    }

    const totalReuse = branchMetrics.reduce((acc, x) => acc + x.totalReuse, 0);
    const totalRecycle = branchMetrics.reduce(
      (acc, x) => acc + x.totalRecycle,
      0
    );
    const reuseWeight = branchMetrics.reduce(
      (acc, x) => acc + x.reuseWeight,
      0
    );
    const recycleWeight = branchMetrics.reduce(
      (acc, x) => acc + x.recycleWeight,
      0
    );

    const totalImpact = branchMetrics.reduce(
      (acc, x) => acc + x.totalImpact,
      0
    );

    const metricPayload = {
      totalReuse,
      totalRecycle,
      reuseWeight,
      recycleWeight,
      totalImpact,
    };

    setMetrics(metricPayload);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <FlexRow my="16px">
      <CircularEconomyMetrics
        loading={loading}
        reuseInventory={metrics?.totalReuse}
        recyclingInventory={metrics?.totalRecycle}
        subtitle="Recovered Devices"
      />

      <SustainabilityMetrics
        loading={loading}
        reuseWeight={metrics?.reuseWeight}
        recycleWeight={metrics?.recycleWeight}
        subtitle="E-Waste Diverted from Landfill"
      />

      <SocialImpactMetrics
        loading={loading}
        totalImpact={metrics?.totalImpact ?? 0}
      />
    </FlexRow>
  );
};


