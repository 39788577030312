import { FlexCol } from "./FlexCol";

type TermsandConditionsProps = {
  version: "version1" | "version2";
  fontWeight?: string;
  textAlign?: string;
  alignItems?: string;
  mb?: string;
} & React.HTMLProps<HTMLDivElement>;

export const TermsAndConditions: React.FC<TermsandConditionsProps> = ({
  version,
  fontWeight,
  textAlign,
  alignItems,
  mb,
  ...props
}) => {
  return (
    <>
      {version === "version1" ? (
        <FlexCol
          flexDir="column"
          alignItems="center"
          fontSize="12px"
          textColor="gray.500"
          textAlign="center"
          px="26px"
          mt="26px"
          {...props}
        >
          These sustainability statistics provide a clear and transparent view
          of your e-waste management, supporting compliance and climate
          disclosure reporting. Zolo is certified to ISO 27001:2013 for
          Information Security Management, ISO 14001:2015 for Environmental
          Management Systems, AS/NZS 5377:2013 for Responsible Recycling and
          Treatment of Electrical and Electronic Equipment (E-Waste), and ISO
          9001:2015 for Quality Management Systems. Our processes are
          independently audited by Compass Assurance Services to ensure
          alignment with globally recognised standards.{" "}
          <span
            className="text-brand-green-300 font-semibold cursor-pointer underline w-fit"
            onClick={() => window.open("https://cas.com.au/", "_blank")}
          >
            Learn More
          </span>
        </FlexCol>
      ) : (
        <FlexCol
          height="300px"
          flexDir="column"
          fontSize="12px"
          textColor="gray.500"
          px="26px"
          my="26px"
          gap="10px"
          overflowY="auto"
          {...props}
        >
          <div>
            <p className="font-bold">General</p>
            <p>
              These sustainability statistics provide a clear and transparent
              view of your e-waste management, supporting compliance and climate
              disclosure reporting.
            </p>
            <p>
              Zolo is certified to ISO 27001:2013 for Information Security
              Management, ISO 14001:2015 for Environmental Management Systems,
              AS/NZS 5377:2013 for Responsible Recycling and Treatment of
              Electrical and Electronic Equipment (E-Waste), and ISO 9001:2015
              for Quality Management Systems.
            </p>
            <p>
              Our processes are independently audited by Compass Assurance
              Services to ensure alignment with globally recognised standards.
            </p>
          </div>
          <div>
            <p className="font-bold">Data Security</p>
            <p>
              All data-bearing devices are processed to eliminate any traces of
              identifiable client information. Our ISO 27001-certified protocols
              ensure:
            </p>
            <ul>
              <li>
                • Data is completely wiped from devices using industry leading
                data erasure tool,{" "}
                <span
                  className="text-brand-green-300 font-semibold cursor-pointer underline w-fit"
                  onClick={() =>
                    window.open("https://www.blancco.com", "_blank")
                  }
                >
                  Blancco
                </span>
                .
              </li>
              <li>
                • Devices are processed to ensure no residual data can be
                recovered or traced back to the client.
              </li>
              <li>
                • A Certificate of Data Erasure is issued upon completion,
                guaranteeing that all sensitive information has been securely
                destroyed.
              </li>
            </ul>
          </div>
          <p className="font-bold">Circular Economy</p>
          <ul>
            <li>
              <span className="font-bold">• Re-use:</span> Devices are
              refurbished, given new life, and reintroduced into circulation to
              maximise their lifecycle.
            </li>
            <li>
              <span className="font-bold">• Recycle:</span> Devices that cannot
              be reused are broken down into material types or parts and
              processed in compliance with ethical and environmental standards.
              Our recycling processes comply with ISO 14001 standards for
              Environmental Management Systems and AS/NZS 5377 standards for
              e-waste treatment.
            </li>
          </ul>
          <div>
            <p className="font-bold">Sustainability Impact</p>
            <p>
              The weight of all devices collected is recorded and categorised
              into re-use or recycle streams.
            </p>
          </div>
          <div>
            <p className="font-bold">Social Impact</p> For every 20 eligible
            laptops collected, Zolo matches and donates a refurbished tablet to
            communities in need. Eligible laptops must be:
            <ul>
              <li>• Less than four years old,</li>
              <li> • Free from screen or water damage.</li>
            </ul>
            <p>
              This program enables businesses to contribute to closing the
              digital divide while supporting Zolo’s mission to drive positive
              change in underprivileged communities.
            </p>
          </div>
        </FlexCol>
      )}
    </>
  );
};
