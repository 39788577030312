import { BaseCard } from "../../form/base.container";
import { FlexCol } from "../FlexCol";
import { formatNumber } from "../../../util/number.util";
import { Box } from "@chakra-ui/react";

export const SocialImpactMetrics = ({
  totalImpact,
  loading,
}: {
  totalImpact: number;
  loading?: boolean;
}) => {
  return (
    <BaseCard
      boxShadow="lg"
      maxW={{ base: "100%", md: "155px" }}
      h={{ base: "100%", md: "140px" }}
      p={{ base: "6px", md: "12px" }}
      fontSize="14px"
      textAlign="center"
    >
      <FlexCol>
        <FlexCol gap="0">
          <div className="text-[16px] text-black">⚡️ Social Impact</div>
          <div className="text-[12px] font-normal">Devices Donated</div>
        </FlexCol>

        <Box
          fontSize="28px"
          mb="12px"
          fontWeight="bold"
          color="brand.green.200"
          fontFamily="zolo-header"
        >
          {formatNumber(totalImpact.toFixed(2))}
        </Box>
      </FlexCol>
    </BaseCard>
  );
};
