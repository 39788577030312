import { useEffect, useState, useContext } from "react";
import {
  Box,
  Divider,
  Flex,
  Tbody,
  Td,
  Text,
  Tr,
  Image,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { ButtonCustom } from "../../components/form/controls/form.button";
import { TableContainer } from "../../components/shared/table/TableContainer";
import { update } from "../../store/slice/Account/company.slice";
import { sortDirection } from "../../util/list.util";
import { AddBranchModal } from "../../components/app/company/add-branch.modal";
import { ellipsesText } from "../../util/string.util";
import { TableContainerMobile } from "../../components/shared/table/TableContainerMobile";
import { Role } from "../../models/enum/role.enum";
import { EmptyContent } from "../../components/shared/EmptyContent";
import { branchService } from "../../service/company/branch.service";
import BusinessIcon from "@mui/icons-material/Business";
import { AppContext } from "../../App";
import Tooltip from "../../components/shared/Tooltip";
import { BranchDetailMetrics } from "../../components/app/company/branch/branch-detail-metrics";

export const Branch = ({}) => {
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([] as any);
  const [addBranchOpen, setAddBranchOpen] = useState(false);
  const { currencySymbol } = useContext(AppContext);

  const dispatch = useDispatch();

  const { role } = useSelector((state: RootState) => state.userProfile);
  const companyState = useSelector((state: RootState) => state.company);
  const { sortColumn, isHeadquarters } = companyState;

  const navigate = useNavigate();

  const isLargeDisplay = useBreakpointValue({
    base: false,
    sm: false,
    md: true,
    lg: true,
    xl: true,
  });

  const handleSort = (props: { accessor: string; direction: 0 | 1 }) => {
    dispatch(
      update({
        ...companyState,
        sortColumn: { accessor: props.accessor, direction: props.direction },
      })
    );

    const sortedCompanies = sortDirection(
      companies,
      props.accessor,
      props.direction
    );

    setCompanies([...sortedCompanies]);
  };

  const columns = [
    {
      header: "Branch name",
      accessor: "name",
      width: "200px",
      callback: (companyName: string) => (
        <Box fontWeight="600">{companyName}</Box>
      ),
    },
    {
      header: "Job",
      width: "100px",

      accessor: "totalJobs",
      callback: (count: any) => <>{+(count ?? 0) === 0 ? "-" : count}</>,
    },
    {
      header: "User",
      width: "100px",
      accessor: "totalUsers",
      callback: (count: any) => <>{+count === 0 ? "-" : count}</>,
    },
    {
      header: "Inventory",
      width: "100px",
      accessor: "totalInventory",
      callback: (count: any) => <>{+count === 0 ? "-" : count}</>,
    },
    {
      header: "Address",
      width: "250px",
      accessor: "addressName",
      callback: (address: string) => <>{ellipsesText(address, 100)}</>,
    },
  ];

  useEffect(() => {
    if (!isHeadquarters) {
      navigate("/");
    }
  }, [isHeadquarters]);

  const init = async () => {
    setLoading(true);
    await branchService.getBranchList().then((companies) => {
      setCompanies(companies);
    }).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Flex
        flexDir="column"
        color="gray"
        gap={2}
        bg="white"
        width="100%"
      >
        <Flex
          my={{ base: "0px", md: "16px" }}
          flexDirection={{ base: "column-reverse", md: "row" }}
          justifyContent="space-between"
          alignItems="center"
          gap="24px"
        >
          <h1>Branches</h1>

          {role === Role.ADMIN ? (
            <ButtonCustom onClick={() => setAddBranchOpen(true)}>
              + Add branch
            </ButtonCustom>
          ) : (
            <Box></Box>
          )}
        </Flex>

        <Flex flexDir="row" gap="4px">
          <BusinessIcon />
          {companies?.length || 0} locations
        </Flex>
      </Flex>

      <BranchDetailMetrics />

      {isLargeDisplay ? (
        <TableContainer
          columns={columns}
          onSort={handleSort}
          sortColumn={sortColumn}
          isEmpty={!loading && !companies?.length}
          emptyChildren={
            <Flex
              justifyContent="center"
              alignItems="center"
              minH="100%"
              minW="100%"
              border="1px solid #ECECEC"
              borderRadius="lg"
            >
              <EmptyContent
                title="No active branches"
                textAlign="center"
                mb={2}
              >
                <Box>
                  Branches help you manage your company's operations in
                  different locations.
                  <br />
                  Get started by adding a branch.
                </Box>
                <ButtonCustom onClick={() => setAddBranchOpen(true)}>
                  + Add branch
                </ButtonCustom>
              </EmptyContent>
            </Flex>
          }
        >
          <Tbody>
            {companies.map((row, i) => (
              <Tr
                _hover={{
                  cursor: "pointer",
                  backgroundColor: "#ECECEC",
                }}
                onClick={() => {
                  navigate(`/app/branch/${row.id}`);
                }}
                key={row.id}
              >
                {columns.map((column, j) => (
                  <Td key={j} height="50px">
                    {column.callback ? (
                      <>{column.callback(row[column.accessor])}</>
                    ) : (
                      <>{row[column.accessor]}</>
                    )}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </TableContainer>
      ) : (
        <TableContainerMobile columns={columns} data={companies} />
      )}

      <AddBranchModal
        isOpen={addBranchOpen}
        onClose={() => setAddBranchOpen(false)}
        onSubmit={init}
      />
    </>
  );
};

const DashboardCard = ({
  type,
  value,
  text,
  iconStyle = undefined,
  tooltip = undefined,
  ...props
}) => {
  const Icon = () => {
    switch (type) {
      case "weight":
        return "battery.svg";
      case "emission":
        return "footprint.svg";
      case "count":
        return "earth.svg";
      case "pickup":
        return "truck.svg";
      default:
        return "care.svg";
    }
  };

  return (
    <Tooltip label={tooltip}>
      <Flex
        pos="relative"
        flexDir="row"
        gap="8px"
        alignItems="center"
        py="6px"
        px="16px"
        borderRadius="10px"
        boxShadow="md"
        bg="white"
        w={{ base: "48%", md: "fit-content" }}
        h={{ base: "125px", md: "fit-content" }}
        {...props}
      >
        <Box style={iconStyle}>
          <Image src={`/assets/dashboard/icons/${Icon()}`} w="50px" />
        </Box>

        <Flex flexDir="column">
          <Box
            color="brand.green.200"
            fontFamily="zolo-header"
            fontSize="18px"
            fontWeight="bold"
          >
            {value}
          </Box>
          <Box color="brand.gray.500" fontWeight="400" fontSize="14px">
            {text}
          </Box>
        </Flex>
      </Flex>
    </Tooltip>
  );
};
