import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Badge, Box, Flex, Grid, Image, Skeleton } from "@chakra-ui/react";

import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { LanguageOutlined } from "@mui/icons-material";
import { CompanyModel } from "../../models/company.model";
import { UserModel } from "../../models/user.model";
import { CardCustom } from "../../components/shared/CardCustom";
import { AvatarCustom } from "../../components/form/avatar.custom";
import { branchService } from "../../service/company/branch.service";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import BusinessCenterOutlined from "@mui/icons-material/BusinessCenterOutlined";
import { BranchDetailUserCard } from "../../components/app/company/user/BranchDetailUserCard";
import { branchUserService } from "../../service/company/branch-user.service";
import { AppContext } from "../../App";
import { useSelector } from "react-redux";
import { BranchDetailMetrics } from "../../components/app/company/branch/branch-detail-metrics";

export const BranchDetail = () => {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState({} as CompanyModel);
  const [users, setUsers] = useState([] as UserModel[]);

  const init = async () => {
    setLoading(true);

    await fetchCompany();

    await branchUserService.getUsers(id).then((users) => {
      setUsers(users);
    });

    setLoading(false);
  };

  const fetchCompany = async () => {
    await branchService.get(id).then(async (company) => {
      setCompany(company);
    });
  };

  useEffect(() => {
    init();
  }, [id]);

  return (
    <>
      <Flex flexDir="row" w="100%" justifyContent="center">
        <Flex pt="30px">
          <Flex pos="relative" direction="column" gap="16px">
            <Image
              pos="absolute"
              src="/assets/branch-bg.png"
              w="100%"
              borderTopRadius="8px"
            />
            <CardCustom
              pt={{ base: "150px", md: "220px" }}
              px={{ base: "16px", md: "40px" }}
            >
              <Flex
                pos="relative"
                flexDir="column"
                justifyContent="space-between"
              >
                <AvatarCustom
                  src={company.photoUri}
                  widthxheight="100px"
                  styles={{ position: "absolute", top: "-110px" }}
                  border="4px solid white"
                />
                <Flex
                  pos="absolute"
                  top="-40px"
                  right="0"
                  flexDir="row"
                  justifyContent="flex-end"
                  w="fit-content"
                >
                  <Flex
                    flexDir="row"
                    gap="6px"
                    fontSize="3xl"
                    fontWeight="bold"
                  >
                    <Flex align="center">
                      <Badge colorScheme={company.deleted ? "red" : "green"}>
                        {company.deleted ? "Inactive" : "Active"}
                      </Badge>
                    </Flex>
                    <Flex align="center">
                      <Badge colorScheme="gray">
                        {company.isHeadquarters ? "Headquarters" : "Branch"}
                      </Badge>
                    </Flex>
                  </Flex>
                </Flex>
                <Box fontSize="24px" style={{ fontFamily: "zolo-header" }}>
                  {loading ? <Skeleton h="30px" my="8px" /> : company.name}
                </Box>
                <Flex flexDir="column" gap="6px" color="gray">
                  <Flex flexDir="row" gap="4px">
                   📍 {company.addressName}
                  </Flex>
                  <Flex hidden={!company.url?.length} flexDir="row" gap="4px">
                    <LanguageOutlined />
                    {company.url}
                  </Flex>
                  <Flex flexDir="row" gap="4px">
                    👥 {(users ?? []).filter((x) => !x.deleted)?.length ?? 0}
                  </Flex>
                </Flex>

                <BranchDetailMetrics branchId={id} />
              </Flex>
            </CardCustom>
            
            <BranchDetailUserCard />
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

const CardsSection = ({ company }) => {
  const { currencySymbol } = useContext(AppContext);
  const companyState = useSelector((state: any) => state.company);

  return (
    <Grid
      mt="16px"
      templateColumns={["repeat(2, 1fr)", "repeat(3, 1fr)"]}
      gap="16px"
    >
      {companyState?.rebateRequired && (

        <Detail
          label="Total credits"
          value={`${currencySymbol}${company.credits}`}
          icon={<PaidOutlinedIcon />}
        />
      )}
      {/* <Detail
        label="Emission (mo.)"
        value={`${company.monthEmission ?? 0}kg`}
        icon={<CompostIcon color="#e29c56" />}
      /> */}

      <Detail
        label="Inventory"
        value={company.totalInventory}
        icon={<BusinessCenterOutlined />}
      />

      {/* <Detail
        label="Total sales"
        value={`$${company.totalSalePrice ?? 0}`}
        icon={<PaidOutlinedIcon />}
      /> */}
    </Grid>
  );
};

const Detail = ({ label, value, icon }) => {
  return (
    <Flex
      flexDirection="column"
      w={{ base: "155px", md: "173px" }}
      h="96px"
      border="1px solid lightGray"
      borderRadius="4px"
      p="12px"
    >
      <Flex flexDir="row" justifyContent="space-between">
        <Box fontSize={{ base: "14px", md: "16px" }} color="brand.gray.400">
          {label}
        </Box>
        <Box color="brand.brown.100">{icon}</Box>
      </Flex>

      <Box
        fontSize={{ base: "17px", md: "20px" }}
        fontWeight="bold"
        color={value ? "brand.green.200" : "brand.gray.400"}
        fontFamily="zolo-header"
      >
        {value ?? "N/A"}
      </Box>
    </Flex>
  );
};
