import { FlexRow } from "../../../shared/FlexRow";
import { useEffect, useState } from "react";
import { InventoryMetricModel } from "../../../../models/inventory-metric.model";
import { inventoryService } from "../../../../service/inventory/inventory.service";
import { CircularEconomyMetrics } from "../../../shared/metrics/CircularEconomyCard";
import { SocialImpactMetrics } from "../../../shared/metrics/SocialImpactCard";
import { SustainabilityMetrics } from "../../../shared/metrics/SustainabilityCard";

export const JobDetailMetrics = ({ job }) => {
  const [loading, setLoading] = useState(true);
  const [metrics, setMetrics] = useState<InventoryMetricModel>(null);

  const init = async () => {
    await inventoryService.getMetrics(job?.id).then((metrics) => {
      setMetrics(metrics);
    }).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    if (job) {
      init();
    }
  }, [job]);

  return (
    <FlexRow mt="26px">
      <CircularEconomyMetrics
        reuseInventory={metrics?.totalReuse}
        recyclingInventory={metrics?.totalRecycle}
        subtitle="Recovered Devices"
      />

      <SustainabilityMetrics
        reuseWeight={metrics?.reuseWeight}
        recycleWeight={metrics?.recycleWeight}
        subtitle="E-Waste Diverted from Landfill"
      />

      {(job?.showWasteImpact && !!metrics?.totalImpact) && (
        <SocialImpactMetrics totalImpact={metrics?.totalImpact ?? 0} />
      )}
    </FlexRow>
  );
};
